<template>
  <p-container class="widget event search form fill-height">
    <p-row column no-gutters nowrap>
      <p-col shrink>
        <h2>{{ $t('page.event-search.title') }}</h2>
      </p-col>
      <p-col shrink>
        <p-card>
          <p-row>
            <p-col xs12 md2>
              <p-select
                v-model="widgetConfig.language"
                :label="$t('page.settings.title.languageSettings')"
                :items="languages"
              ></p-select>
            </p-col>
            <p-col xs12 md3>
              <p-text-field
                v-model="widgetConfig['widget-toccofilter']"
                :label="$t('page.event-next.label.toccoFilter')"
              ></p-text-field>
            </p-col>
            <p-col xs12 md1>
              <p-text-field
                v-model="widgetConfig['widget-pagesize']"
                :label="$t('page.event-next.label.pageSize')"
                type="number"
              ></p-text-field>
            </p-col>
            <p-col xs12 md2>
              <!-- <p-text-field
                v-model="widgetConfig.searchtag"
                :label="$t('page.event-next.label.displayType')"
              ></p-text-field> -->
            </p-col>
            <p-col xs12 md3 class="actions align-end justify-end">
              <p-button @click="openWidget(widgetConfig.language)">
                {{ $t('core.app.ok') }}
              </p-button>
              <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                  dataset_linked
                </p-icon>
                <p-icon right>
                  {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                </p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-card>
      </p-col>
      <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0">
        <div v-if="!link">
          {{ $t('widget.config-pages.embed-info.no-link-message') }}
        </div>
        <template v-else>
          <p-col shrink>
            <p-row align-center>
              <p-col shrink>
                <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
              </p-col>
              <p-col grow>
                <p-row dense>
                  <p-button secondary @click="_.copyToClipboard($refs.link)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-col>
          <p-divider class="mb-4"></p-divider>
          <p-col>
            <p-row>
              <p-col grow>
                <pre ref="widgetEmbedCode" class="code overflow-auto">{{ widgetEmbedSnippet }}</pre>
              </p-col>
              <p-col shrink :xs12="!$media.isDesktop">
                <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                  <p-icon>content_copy</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-card>
      <template v-if="link">
        <p-col>
          <div id="result" class="fill-height fill-width px-5 pt-4">
            <!-- WIDGET RENDERED HERE -->
          </div>
        </p-col>
      </template>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    role: ['Admin', 'HostAdmin'],
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      widgetHost: `${window.location.protocol}//${window.location.host}`,
      widgetConfig: {
        'mount-id': 'widgetContainer335',
        route: '/_widget/events',
        language: 'de',
        'widget-toccofilter': '1==1' as string,
        'widget-pagesize': 5 as number,
        searchtag: undefined as string | undefined,
      },
      widgetEmbedSnippet: undefined as string | undefined,
      link: undefined as string | undefined,
      host: `${window.location.protocol}//${window.location.host}`,

    }),
    computed: {
      languages(): { id: string, caption: string, description: string }[] {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`core.page.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
    },
    mounted() {
      this.widgetConfig.language = this.$config.getQueryOrValue('language') ?? this.widgetConfig.language;
    },
    methods: {
      createWidgetEmbedSnippet(scriptQueries?: Record<string, string>) {
        const widgetConfig = this.widgetConfig as Record<string, any>;
        const widgetAttributes = Object.keys(widgetConfig)
          .filter((key) => widgetConfig[key] !== undefined)
          .map((key) => `data-${key}="${widgetConfig[key]}"`)
          .join('\n ');
        const queries = scriptQueries ? `?${Object.keys(scriptQueries).map((i) => `${i}=${scriptQueries[i]}`).join('&')}` : '';
        return `
          <link href="${this.widgetHost}/css/app.css" rel="stylesheet" />
          <div id="${widgetConfig['mount-id']}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.widgetHost}"
              data-auth-apikey="GL-*****************************"
              src="${this.widgetHost}/js/app.js${queries}"
              ${widgetAttributes}
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      async openWidget(language:string) {
        const queries = { language } as Record<string, string>;
        const queryString = Object.keys(queries)
          .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
          .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
          .join('&');
        this.link = new URL(`${this.widgetHost}/${this.widgetConfig.route}?${queryString}`, document.baseURI).href;
        this.widgetEmbedSnippet = this.createWidgetEmbedSnippet({ cacheBust: Date.now().toString() });
        this.$nextTick(() => {
          const resultElement = document.getElementById('result') as HTMLElement;
          resultElement.replaceChildren(document.createRange().createContextualFragment(this.widgetEmbedSnippet!));
        });
      },
    },
  });
  </script>

<style>
.angebote.search {
    width: 100%;
}
.search.angebote .inputfilter{
    border-bottom: solid 1px black;
    margin:10px;
}
.search.angebote input{
  border:solid 1px silver;
  margin:5px;
}

.angebote.liste .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0
}

.angebote.liste .item .image {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.angebote.liste .item .image .backgroundimage {
    width:200px;
    height:200px;
    /* width: 100%;
    height: 53vw; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.angebote.liste .item .circle.red.waitinglist:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat
}

.angebote.liste .item .text_wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 15px;
    line-height: 1.5rem
}

.angebote.liste .item .text_wrapper>.row:last-child>div:last-child {
    text-align: right
}

.angebote.liste .item .text_wrapper .titel {
    font-size: 1.5rem;
    line-height: 1.2em;
    margin-bottom: 10px;
    cursor: pointer
}

.angebote.liste .item .text_wrapper .detail,.angebote.liste .item .text_wrapper .termine {
    display: block;
    text-decoration: none;
    text-align: right;
    cursor: pointer
}

.angebote.liste .item .text_wrapper .detail:before,.angebote.liste .item .text_wrapper .termine:before {
    line-height: 1.5rem !important
}

.angebote.liste .item .text_wrapper a.detail {
    display: inline-block
}

.angebote.liste .item .text_wrapper .datum,.angebote.liste .item .text_wrapper .ort {
    font-weight: bold;
    cursor: pointer
}

.angebote.liste .item .text_wrapper .preis {
    cursor: pointer;
    display: none
}

.angebote.liste .item .text_wrapper>div:last-child>div:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.angebote.liste .item .text_wrapper .ghostbutton.anmelden {
    padding: 3px 10px;
    width: 110px;
    text-align: center;
    margin-top: auto;
    margin-left: auto
}

.angebote.liste .item .text_wrapper .status {
    font-size: 0.8rem;
    margin-top: 30px;
    display: inline-block;
    padding-left: 28px;
    position: relative
}

.angebote.liste .item .text_wrapper .status .circle {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 1px
}

.angebote.liste .item .text_wrapper .detaillinkwrapper:after {
    content: "\00a0"
}

.angebote.liste .item .text_wrapper .terminliste {
    padding-top: 20px;
    padding-bottom: 20px
}

.angebote.liste .item .text_wrapper .terminliste ul {
    list-style: none;
    margin: 15px 0;
    -webkit-columns: 1 auto;
    columns: 1 auto
}</style>

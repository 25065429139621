<template>
  <p-container>
    <p-row column>
      <p-col justify-center>
        <h2>Swissmechanic - IL</h2>
      </p-col>
      <p-col justify-center>
        <span class="caption-1">Integrationlayer for Swissmechanic</span>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
  });
</script>
